.b-comparison-table {
    padding: 48px 0;
    position: relative;
}

.b-comparison-table__header {
    z-index: 2;
    position: relative;
}


.b-comparison__bg-image--desktop {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
}

.b-comparison__bg-image--mobile {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.b-comparison-table__headline{
    text-align: center;
   margin-bottom: 32px;

}

.primary-col .column-row-content {
    background-color: unset;
    border-bottom: 1px solid #E5CBD8;
}

.column-row-content:nth-of-type(1) {
    border: none;
}


.b-comparison-table__wrapper {
    position: relative;
}

.compare-section-first__image,
.compare-section-last__image {
    display: none;
}

/* Table CSS */

.b-comparison-table__table {
    display: flex;
    justify-content: center;
}

.b-comparison-table__table-container {
    display: flex;
    align-items: flex-end;
}

.b-comparison-table__column-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 244px;
}


.primary-col .column-row-content:first-child {
    border-radius: 4px 4px 0 0;
    border-bottom-color: #E7E7E7;
}

.primary-col .column-row-content:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: none;
}

.table-column-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.table-column-content {
    width: 162px;
}

.table-column-content.primary-col {
    width: 162px;

}

.table-column-content:last-child .column-row-content:first-child {

    height: 80px;
}

.table-column-content.primary-col p {
    color: #414D57 !important;
}

.table-column-content .column-row-content {
    width: 100%;
    color: #98A4AE;
}

.table-column-content:first-child {
    border-bottom: none;
}

.table-column-content .column-row-content:first-child p {
    font-size: 16px;
    color: #414D57;
}

.b-comparison-table__button {
    width: 320px;
    background-color: #D7E786;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center  ;
}


.table-column-content .column-row-content {
    border-bottom: 1px solid #E5CBD8;
}

.b-comparison-table__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 5px;
}

.b-comparison-table__info {
    font-size: 14px;
    text-align: center;
    color: #262626;
}

.row-title {
    display: flex;
    align-items: center;
    color: #414D57 !important;
    font-weight: 400;
    height: 72px;
    font-size: 14px;
    line-height: 16px;
    background-color: transparent;
    border-bottom: 1px solid #98A4AE;
    color: var(--hoox-color-white);
}



.row-title:last-child {
    border: none;
}

.row-title--empty {
    background: transparent !important;
    display: block !important;
    border-bottom: 1px solid var( --hoox-color-slate-medium);
}

.column-row-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 103px;
    height: 56px;
}


.primary-col .column-row-content:first-child{
    height: 80px;
}

.column-row-content:last-child {
    border: none !important;
}

.column-row-content p {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    text-align: center;
}


.table-column-content .column-row-content p {
    max-width: 126px;
}


.column-row-content {
    background-color: transparent;
    border-bottom: 1px solid #E5CBD8;
}


.table-swiper-mob__inner {
    box-sizing: border-box !important;
}

.table-column-content:last-child .column-row-content {
    border-bottom: 1px solid #98A4AE;
}

@media(min-width:768px) {
    .b-comparison-table {
        padding: 76px 0;
    }

    .table-column-content {
        width: 200px;
    }

    .table-column-content.primary-col {
        width: 200px;
    }

    .table-column-content:last-child .column-row-content:first-child {
        height: 80px;
    }

    .b-comparison-table__headline {
        margin-bottom: 43px;
    }

    .table-swiper-mob__inner .swiper-slide {
        flex-shrink: 1 !important;
    }

    .row-title {
        width: 200px;
        height: 56px;
        font-size: 16px;
    }

    .row-title p {
        max-width: 176px;
        color: #414D57;
    }

    .column-row-content {
        width: 164px;
        font-size: 14px;
        line-height: 21px;
        height: 56px;
    }

    .column-row-content p{
        max-width: 90px;
    }

    .primary-col .column-row-content:first-child{
        height: 80px;
        border-bottom: 1px solid #E5CBD8;
    }

    .b-comparison-table__navigation {
        display: none;
    }

    .column-row-content:first-of-type {
        padding-top: 0px;
    }
    .table-column-content .column-row-content p {
        font-size: 16px;
    }


}

@media(max-width: 767px) {
    .b-comparison-table__table-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .table-column-content .column-row-content p {
        max-width: 97px;
    }


    .table-column-content .column-row-content:first-child p {
        font-size: 14px;
        font-weight: 400;
        color: #414D57;
    }

    .primary-col .column-row-content:first-child {
        height: 72px;
        border-bottom: 1px solid #E5CBD8;
    }

    .table-column-content .column-row-content {
        width: 100%;
        height: 72px;
        border-bottom: 1px solid #E5CBD8;
    }

    .b-comparison-table__column-title {
        min-width: 112px;
    }

    .column-row-content:first-child img {
        width: 100%;
        padding: 5px;
    }

    .column-row-content figure {
        margin: 12px 0 0;
        max-width: 97px;
        max-height: 43px;
    }

    .primary-col {
        max-width: 110px;
    }

    .b-comparison-table__image {
        display: none;
    }

    .table-column-content {
        width: 111px;
    }

    .table-column-content.primary-col {
        max-width: 112px;

    }

    .table-swiper-mob {
        display: flex;
        padding-bottom: 80px;
        margin-right: unset;
        width: 100%;
        z-index: 3;
    }

    .table-column-content.swiper-slide {
        width: 100% !important;
        margin-left: auto;
    }

    .table-swiper-mob .swiper-scrollbar-drag {
        display: block !important;
    }

    .swiper-slide .column-row-content {
        margin-left: auto;
    }

    .b-comparison-table__table-container .swiper-scrollbar {
        background: #9FD7E7;
        height: 3px;
    }

    .b-comparison-table__table-container .swiper-scrollbar-drag {
        background: #033282;
    }

    .b-comparison-table__navigation {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        min-width: 80px;
    }

    .b-comparison-table__navigation svg {
        width: 12px !important;
        height: 24px;
    }

    .b-comparison-table__navigation .swiper-button-prev::after,
    .b-comparison-table__navigation .swiper-button-next::after {
        content: none;
    }

    .b-comparison-table__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .b-comparison-table__navigation .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #333132;
    }

    .b-comparison-table__navigation .swiper-pagination-bullet {
        border: 1px solid #333132;
        background-color: transparent;
        opacity: 1;
    }

    .row-title p {
        max-width: 105px;
    }

    .table-column-content:last-child .column-row-content:first-child {
        height: 72px;
    }

}


@media(max-width: 992px) {
    .comparsion__bg-image--desktop {
        display: none;
    }
}

@media(min-width: 993px) {
    .b-comparison__bg-image--mobile {
        display: none;
    }
}


.b-comparison-table-lp-3 .table-column-content .column-row-content, .b-comparison-table-lp-2 .table-column-content .column-row-content{
    border-bottom: 1px solid var( --hoox-color-slate-medium);
}

.b-comparison-table-lp-3 .table-column-content.primary-col svg rect, .b-comparison-table-lp-2 .table-column-content.primary-col svg rect {
    fill: var(--hoox-color-slate-dark);
}

.b-comparison-table-lp-3 .table-column-content.primary-col svg path, .b-comparison-table-lp-2 .table-column-content.primary-col svg path {
    fill: var(--hoox-color-white);
}

.b-comparison-table__goat {
    position: absolute;
    right: 80px;
    bottom: 40px;
    max-width: 100px;
}

@media (max-width: 1024px) {
    .b-comparison-table__goat {
        display: none;
    }
}

@media (max-width: 768px) {
    .b-comparison-table-lp-2 {
        padding: 42px 0 48px;
    }

    .b-comparison-table-lp-2 .b-comparison-table__headline, .b-comparison-table-lp-3 .b-comparison-table__headline {
            margin-bottom: 45px;
    }

    .b-comparison-table-lp-2 .b-comparison-table__table-container, .b-comparison-table-lp-3 .b-comparison-table__table-container {
        max-width: 325px;
    }

    .b-comparison-table-lp-2 .table-column-content.primary-col, .b-comparison-table-lp-3 .table-column-content.primary-col, .b-comparison-table-lp-3 .b-comparison-table__column-title{
        max-width: 108px;
    }



    .b-comparison-table-lp-2 .row-title br, .b-comparison-table-lp-3 .row-title br{
        display: none;
    }

    .b-comparison-table-lp-3 .column-row-content figure {
        margin-top: -5px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
    .b-comparison-table-lp-2 .table-column-content.primary-col, .b-comparison-table-lp-3 .table-column-content.primary-col, .b-comparison-table-lp-3 .b-comparison-table__column-title {
        max-width: 200px;
    }
    .b-comparison-table-lp-2 .b-comparison-table__table-container, .b-comparison-table-lp-3 .b-comparison-table__table-container {
        max-width: unset;
    }
}
